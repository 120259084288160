<!-- 角色管理 -->
<template>
	<div class="doctor_management">
		<el-row class="bread_crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/RoleManagement' }">系统管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/RoleManagement' }">权限管理</el-breadcrumb-item>
			</el-breadcrumb>
		</el-row>
		<div class="public_bg">
			<el-row>
				<el-form ref="formInline" :model="formInline" :inline="true" class="demo-form-inline">
					<el-form-item label="角色名称:" prop="roleName">
						<el-input type="input" v-model="formInline.roleName" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="queryRoleInfo">查询</el-button>
						<el-button @click="resetForm('formInline')">重置</el-button>
						<el-button type="primary" icon="el-icon-plus" @click="addRole('新增',{})">新增</el-button>
					</el-form-item>
				</el-form>
			</el-row>
			<el-row>
				<el-table stripe :header-cell-style="{background:'#FAFAFA'}" :data="RolePageData">
					<el-table-column label="序号" prop="num" align="left" width="100px">
					</el-table-column>
					<el-table-column label="角色名称" prop="roleName" align="left">
					</el-table-column>
					<el-table-column label="创建日期" prop="creationDate" align="left">
					</el-table-column>
					<el-table-column label="操作" align="left">
						<template slot-scope="scope">
							<!-- <el-button size="mini" type="primary" @click="RoleAllocatePrsonnel(scope.row)">为角色分配人员</el-button> -->
							<el-button size="mini" type="text" @click="addRole('编辑',scope.row)">编辑</el-button>
							<el-button size="mini" type="text" @click="showAuthority(scope.row.roleId)">授权</el-button>
							<el-button size="mini" type="text" @click="delRole(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<v-paging v-on:childPage="pages" :total=total></v-paging>
			</el-row>
			<!-- 编辑新增模态框 -->
			<el-dialog :title="title" :visible.sync="DialogVisible" width="40%">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="角色名称:" prop="roleName">
						<el-input placeholder="请输入" v-model="ruleForm.roleName"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="DialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="DialogVisibleSave">确 认</el-button>
				</span>
			</el-dialog>
			<!-- 删除模态框 -->
			<el-dialog :title="title" :visible.sync="DialogChangeVisible" width="40%">
				<el-form :model="ruleFormChange" :rules="rulesDelete" ref="ruleFormChange" label-width="100px" class="demo-ruleForm">
					<el-form-item label="删除原因" prop="remark">
						<el-input v-model="ruleFormChange.remark" placeholder="请输入"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="DialogChangeVisible = false">取 消</el-button>
					<el-button type="primary" @click="DialogChangeVisibleSave">确 认</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		data: function() {
			return {

				RolePageData: [],
				formInline: {},
				ruleForm: {},
				rules: { //新增
					roleName: [{
						required: true,
						message: '请填写角色名称',
						trigger: 'blur'
					}]
				},
				rulesDelete: {
					remark: [{
						required: true,
						message: '请填写删除原因',
						trigger: 'blur'
					}]
				},
				title: "",
				fromData: [], //授权原数据
				toData: [], //授权目标数据
				fromRoleData: [], //角色授权原数据
				toRoleData: [], //角色授权目标数据
				AuthorityDialog: false, //功能授权模态框
				DialogVisible: false, //编辑新增模态框
				total: 0,
				ruleFormChange: {}, //删除角色
				DialogChangeVisible: false

			}
		},
		components: {
			vPaging
		},
		methods: {
			//分页接口
			async QueryRoleQueryPage(current = 1, size = 10) {
				let info = {
					current: current,
					size: size,
					roleName: this.formInline.roleName
				}
				let res = await this.$Http.RoleQueryPage(info)
				if (res.code == '200') {
					this.RolePageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.RolePageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//分页组件
			pages(current, size) {
				this.QueryRoleQueryPage(current, size);
			},
			// 删除
			delRole(RoleList) {
				this.DialogChangeVisible = true;
				this.RoleList = RoleList;
			},
			//确认删除角色
			DialogChangeVisibleSave() {
				this.$refs["ruleFormChange"].validate((valid) => {
					if (valid) {
						this.QueryRoleChange()
					} else {
						return false;
					}
				});
			},
			//确认删除接口
			async QueryRoleChange() {
				let info = {
					roleId: this.RoleList.roleId,
					remark: this.ruleFormChange.remark,
					version: this.RoleList.version
				}
				let res = await this.$Http.RoleChange(info);
				if (res.code == '200') {
					this.DialogChangeVisible = false;
					this.QueryRoleQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			// 查询
			queryRoleInfo() {
				this.QueryRoleQueryPage();
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 编辑新增
			addRole(val, item) {
				this.DialogVisible = true;
				this.title = `${val}角色`
				this.ruleForm = JSON.parse(JSON.stringify(item));
			},
			//新增角色接口
			async QueryRoleInsertByInfo() {
				let info = {
					roleName: this.ruleForm.roleName,
				}
				let res = await this.$Http.RoleInsertByInfo(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.QueryRoleQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//编辑角色接口
			async QueryRoleUpdate() {
				let info = {
					roleId: this.ruleForm.roleId,
					roleName: this.ruleForm.roleName,
					version: this.ruleForm.version
				}
				let res = await this.$Http.RoleUpdate(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.QueryRoleQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			// 编辑新增保存
			DialogVisibleSave() {
				this.$refs["ruleForm"].validate((valid) => {
					if (valid) {
						if (this.title == "新增角色") {
							this.QueryRoleInsertByInfo();
						} else {
							this.QueryRoleUpdate();
						}
					} else {
						return false;
					}
				});
			},
			// 授权
			showAuthority(roleId) {
				localStorage.setItem("roleId", roleId);
				this.$router.push({
					path: '/Authorization',
				})
			},
		},
		created() {
		},
		mounted() {
			//分页
			this.QueryRoleQueryPage();
		}
	}
</script>
<style scoped>

</style>
