<!-- 分页组件-->
<template>
	<div class="Paging_sty">
		<div class="block">
            <el-pagination background  @size-change="handleSizeChange"  @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="10" layout=" prev, pager, next,sizes,jumper,total"  :total="total">
            </el-pagination>
        </div>
	</div>
</template>
<script>
    export default {
		props: {
			total:Number,
		},
		data() {
			return {
				currentPage:1,
			}
		},
		methods: {
			handleSizeChange(val) { // 分页-每页几条
			    this.size=val;
				this.$emit('childPage',this.current,val);
			},
			handleCurrentChange(val) {// 分页-当前页
				this.current=val;
				this.$emit('childPage',val,this.size);
            },
		},
		mounted() {
			
		}
	}
</script>
<style lang="scss" scoped>
	.Paging_sty{
		margin-top: 20px;
		text-align: center;
	}
</style>
